/* --------Font--------------- */
/* poppins-300 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/poppins-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-300-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-300.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/poppins-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-regular-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-regular.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/poppins-v20-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-italic-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-italic.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/poppins-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-500-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-500.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/poppins-v20-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-500italic-1.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-500italic.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/poppins-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-600-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-600.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/poppins-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-700-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-700.svg")
      format("svg"); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/poppins-v20-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v20-latin-800-1.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v20-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v20-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v20-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v20-latin-800.svg")
      format("svg"); /* Legacy iOS */
}

/* -----------Css-variable------ */

:root {
  --light-purple: #f6f4fe;
  --purple: #6a49f2;
  --bg-purple: #6a49f2;
  --dark-purple: #32236f;
  --body-text-purple: #3e3f66;
  --text-white: #123258;
  --bg-white: #ffffff;
  --slider-dots-color: #d4d2dd;
  --light-bg: #dfdaf3;
}

/* ------Common-Css------------- */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  font-size: 16px !;
  line-height: 1.7;
  font-family: "Poppins", sans-serif !important;
  color: #000;
  background-color: #fff;
}

.page_wrapper {
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--body-text-purple);
}

a:hover {
  text-decoration: none;
  color: var(--body-text-purple);
}

ul,
li {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

ul {
  padding: 0 !important;
}
button:focus,
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}

.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px !important;
}

.navbar-brand img {
  width: 250px;
}

.ml-auto {
  margin-left: auto;
}

/* header wraper */
header {
  position: absolute;
  width: 100%;
  z-index: 111;
  transition: 0.4s all;
}

header.fix_style {
  position: fixed;
  top: 0;
  backdrop-filter: blur(5px);
  background-color: #ffffff;
  padding: 15px 0;
  transition: none;
  opacity: 0;
  pointer-events: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

header.fixed {
  pointer-events: all;
  opacity: 1;
  transition: 0.4s all;
}

header.fixed .navbar {
  padding: 0 !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 5px 20px;
  font-weight: 500;
  color: #123258;
  font-size: 16px;
}

/* ---------Hero-banner-Css-Start------------------ */
/* hero banner wraper */
.banner_section {
  padding-top: 180px;
  position: relative;
  background: #fff;
}

.banner_section .container {
  position: relative;
}

/* wave backgound after banner */
.banner_section::after {
  /*content: "";
      display: block;
      background-image: url(../images/banner-shape.svg);
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 200px;
      background-position: center;
      margin-top: -50px;*/
}

.banner_section .row {
  align-items: center;
}

/* hero banner text */
.banner_section .banner_text {
  margin-top: -50px;
  text-align: left;
}

/* hero banner heading h1 */
.banner_section .banner_text h1 {
  font-size: 55px;
  color: #123258;
  letter-spacing: -1.5px;
  font-weight: 700;
}

.banner_section .banner_text h1 span {
  color: #123258;
}

.banner_section .banner_text p {
  color: #123258;
}

/* hero banner button */
.banner_section .app_btn {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

/* hero banner list */
.banner_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--purple);
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

.banner_section .app_btn li a img {
  transition: 0.4s all;
}

.banner_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.banner_section .app_btn li a:hover {
  background-color: var(--purple);
  border: 2px solid var(--bg-white);
}

.banner_section .app_btn li a:hover .blue_img {
  opacity: 0;
}

.banner_section .app_btn li a:hover .white_img {
  opacity: 1;
}

/* hero banner users */
.banner_section .used_app {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -15px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
  color: #123258;
}

/* hero banner images */
.banner_section .banner_image {
  display: flex;
  position: relative;
  width: 100%;
}

.banner_section .banner_image img {
  max-width: 100%;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: 130px;
}

/* hero banner shape animation */
.banner_section .banner_shape1,
.banner_shape2,
.banner_shape3 {
  position: absolute;
}

.banner_section .banner_shape1 {
  top: 200px;
  left: -15%;
  animation: mymove 10s infinite;
}

.banner_section .banner_shape2 {
  top: 100px;
  right: -10%;
  animation: mymove 5s infinite;
}

.banner_section .banner_shape3 {
  top: 400px;
  right: -20%;
  animation: mymove 3s infinite;
}

ul.list-inline li img {
  width: 160px;
}
ul.app_btn li img {
  width: 160px;
}

.green_color {
  color: #18df80 !important;
}

.banner_section .banner_text h1 {
  font-size: 55px;
  color: #123258;
  letter-spacing: -1.5px;
  font-weight: 700;
}

.action-btns ul {
  text-align: left;
}

/* trusted logos wraper */
.trusted_section {
  padding-top: 40px;
  background: #fff;
}
.trusted_section .company_logos {
  padding-top: 20px;
}

.trusted_section .company_logos img {
  filter: grayscale(1);
  margin: 0 auto;
  transition: 0.4s all;
}

.trusted_section .company_logos img:hover {
  filter: grayscale(0);
}

/* about us section wraper */
.about_app_section .about_img {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

/* about us section images*/
.about_app_section .about_img img {
  max-width: 100%;
}

.about_app_section .about_img::before {
  content: "";
  position: absolute;
  left: 28%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: #f6f8fb;
  border-radius: 100%;
  z-index: -1;
}
.about_app_section .about_img .screen_img {
  margin-left: -135px;
  margin-top: 110px;
}

.about_app_section .about_text .section_title {
  text-align: left;
}

.about_app_section .about_text .section_title h2 {
  margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
  width: 248px;
  background-color: var(--bg-white);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 15px 10px;
  padding-left: 35px;
  box-shadow: 0px 4px 10px #ede9fe;
}

.about_app_section .about_text .app_statstic li .icon {
  margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
  margin-bottom: 0;
  line-height: 1;
  color: #000000;
}

.about_app_section .about_text .app_statstic li p:first-child {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3px;
}

/* -------------Modern-Ui-Section-Css-Start---------------- */
/* modern ui section wraper */
.modern_ui_section .row {
  align-items: center;
}

.modern_ui_section .design_block {
  margin-top: 45px;
}

.section_title h2 {
  font-size: 35px;
  font-weight: 700;
  color: #123258;
}
.puprple_btn {
  background-color: #123258;
  color: #fff;
  border-radius: 50px;
  padding: 10px 40px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 500;
}
.section_title h2 span {
  color: #36e390;
}
.section-heading h2 {
  font-size: 40px;
  font-weight: 700;
  color: #123259;
}

.puprple_btn {
  background-color: #123258;
  color: #fff;
  border-radius: 50px;
  padding: 10px 40px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 500;
}

.about_text {
  text-align: left;
}
.row_am {
  padding: 90px 0;
}

.section-heading {
  text-align: left !important;
}

.promo-section .owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.promo-section .owl-carousel .owl-stage-outer {
  padding: 20px;
}

/* -------------Modern-Ui-Section-Css-Start---------------- */
/* modern ui section wraper */
.modern_ui_section .row {
  align-items: center;
}

.modern_ui_section .design_block {
  margin-top: 45px;
}

/* modern ui text */
.modern_ui_section .section_title {
  text-align: left;
}

.modern_ui_section .ui_text {
  padding-right: 75px;
}

/* modern ui list */
.modern_ui_section .design_block li {
  padding-left: 40px;
  position: relative;
  margin-bottom: 25px;
}

.modern_ui_section .design_block li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background-image: url(../images/right_icon.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.modern_ui_section .design_block li h4 {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 8px;
}

.modern_ui_section .design_block li p {
  margin-bottom: 0;
}

/* modern ui images */
.modern_ui_section .ui_images {
  display: flex;
  position: relative;
  justify-content: center;
}

.modern_ui_section .ui_images::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 570px;
  border-radius: 100%;
  background-color: var(--bg-white);
  z-index: -1;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  margin-left: -140px;
  margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  margin-left: -90px;
  margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  position: relative;
  top: 15px;
  z-index: 99;
  margin-left: -15px;
}

.left_img img {
  width: 450px;
}

/* -------------How_It_Works-Section-Css-Start------------------ */

/* how it works wraper */
.how_it_works .container {
  max-width: 1370px;
}

.how_it_works .how_it_inner {
  background-color: var(--bg-white);
  padding: 70px 0;
  /* padding-bottom: 250px; */
  border-radius: 30px;
  box-shadow: 0px 4px 30px #ede9fe;
}

/* how it works list */
.how_it_works .step_block ul {
  max-width: 1080px;
  margin: 0 auto;
  padding: 10px;
}
.step_img img {
  width: 520px;
}
.how_it_works .step_block ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
}

.how_it_works .step_block ul li::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: 4px;
  height: calc(100% + 100px);
  background-color: #123258;
}

.how_it_works .step_block ul li:first-child::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  background-color: #123258;
  border-radius: 15px;
}

.how_it_works .step_block ul li:first-child::before {
  top: 0;
}

.how_it_works .step_block ul li:last-child::before {
  height: 50%;
  top: 0;
}

.how_it_works .step_block ul li .step_text,
.how_it_works .step_block ul li .step_img {
  width: 360px;
  text-align: right;
}

/* how it works image */
.how_it_works .step_block ul li .step_img img {
  max-width: 100%;
}

/* how it works heading h4 */
.how_it_works .step_block ul li .step_text h4 {
  font-size: 20px;
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text .app_icon {
  margin-bottom: 10px;
}

.how_it_works .step_block ul li .step_text .app_icon a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--light-bg);
  color: #123258;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transition: 0.4s all;
}

.how_it_works .step_block ul li .step_text .app_icon a:hover {
  background-color: var(--purple);
}

.how_it_works .step_block ul li .step_text span {
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text span a {
  color: var(--purple);
  text-decoration: underline;
}

.how_it_works .step_block ul li:nth-child(2) {
  flex-direction: row-reverse;
}

.how_it_works .step_block ul li:nth-child(2) .step_text,
.how_it_works .step_block ul li:nth-child(2) .step_img {
  text-align: left;
}

/* how it works numbers */
.how_it_works .step_block ul li .step_number {
  /* background-image: url(../images/icon_bg.png); */
  background-size: cover;
  background: #123258;
  background-repeat: no-repeat;
  background-position: center;
  width: 110px;
  height: 110px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #ffffff;
}

/* how it works numbers heading h3 */
.how_it_works .step_block ul li .step_number h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: -5px;
}

/* how it works video  */
.how_it_works .yt_video {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: -200px;
  position: relative;
  overflow: hidden;
}

.how_it_works .yt_video .thumbnil {
  position: relative;
}

.how_it_works .yt_video .thumbnil img {
  max-width: 100%;
}

.how_it_works .yt_video .thumbnil a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #123258;
  font-weight: 600;
  z-index: 999;
  cursor: pointer;
}

.how_it_works .yt_video .thumbnil a span {
  display: block;
  font-weight: 700;
  font-size: 30px;
}

.how_it_works .yt_video .thumbnil a .play_btn {
  background-color: rgba(255, 255, 255, 0.1);
  width: 96px;
  height: 96px;
  border-radius: 100px;
  text-align: center;
  margin: 0 auto;
  line-height: 96px;
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.how_it_works .yt_video .thumbnil a .play_btn img {
  width: 50px;
  position: relative;
  z-index: 999;
}

.how_it_works .how_it_inner {
  background-color: var(--bg-white);
  padding: 70px 0;
  /* padding-bottom: 250px; */
  border-radius: 30px;
  box-shadow: 0px 4px 30px #beead58a;
}

.how_it_works .container {
  max-width: 1370px !important;
}

/* ------------Testimonial-Slider-Css-Start------------- */
/* testimonials wraper  */
#testimonial_slider {
  max-width: 550px;
  margin: 0 auto;
}

.testimonial_section .testimonial_block {
  background-image: url("../images/testimonial_bg.png");
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 65px;
}

.testimonial_section .testimonial_block .testimonial_slide_box {
  text-align: center;
  width: 430px;
  padding: 10px;
  margin: 0 auto;
}

/* testimonials rating  */
.testimonial_section .testimonial_block .rating span {
  color: #18df80;
  font-size: 18px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .review {
  margin-top: 10px;
  margin-bottom: 30px;
}

/* testimonials image  */
.testimonial_section
  .testimonial_block
  .testimonial_slide_box
  .testimonial_img
  img {
  margin: 0 auto;
}

/* testimonials heading h3 */
.testimonial_section .testimonial_block .testimonial_slide_box h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .designation {
  font-size: 15px;
}

/* testimonials total review */
.testimonial_section .total_review {
  text-align: center;
  margin-top: 60px;
}

.testimonial_section .total_review .rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* testimonials paragraph */
.testimonial_section .total_review .rating p {
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 5px;
  color: #000000;
}

/* testimonials heading */
.testimonial_section .total_review h3 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000000;
}

.testimonial_section .total_review a {
  color: #212529;
  font-weight: 700;
}

.testimonial_section .testimonial_block .avtar_faces {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
}

.testimonial_section .testimonial_block .avtar_faces img {
  max-width: 100%;
}

/* download app wraper */
.free_app_section {
  position: relative;
  z-index: 0;
}

.free_app_section .container {
  max-width: 1370px;
}

.free_app_section .container .free_app_inner {
  background-color: #123258;
  border-radius: 30px;
  padding: 20px 100px;
  padding-bottom: 50px;
  position: relative;
  z-index: 999999;
}

/* download app dark background */
.free_app_section .container .free_app_inner .dark_bg {
  overflow: hidden;
}

.free_app_section .container .free_app_inner .dark_bg span {
  z-index: 9999;
}

.free_app_section .container .free_app_inner .row {
  align-items: center;
}

.free_app_section .container .free_app_inner .free_text .section_title {
  text-align: left;
}

/* download app heading h2 */
.free_app_section .container .free_app_inner .free_text .section_title h2 {
  margin-bottom: 20px;
}

.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
  color: #123258;
}

.free_app_section .container .free_app_inner .free_text .app_btn {
  display: flex;
  align-items: center;
}

/* .free_app_section .container .free_app_inner .free_text .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
} */

.free_app_section .container .free_app_inner .free_text .app_btn li a:hover {
  -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
}

.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {
  margin-left: 25px;
}

.free_app_section .container .free_app_inner .free_img {
  display: flex;
  align-items: center;
  margin-top: -120px;
}

.free_app_section .container .free_app_inner .free_img img:last-child {
  margin-left: -65px;
}
.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
  color: #fff;
}

.free_app_section .container {
  max-width: 1370px !important;
}

.testimonial_slide_box .rating {
  display: flex !important;
  justify-content: center;
  gap: 5px;
}

.testimonial_section .total_review .rating span {
  margin-bottom: 4px;
}

/* purple button */

.puprple_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--bg-white);
  border-radius: 50px;
  transition: 0.6s all;
  z-index: -1;
}

.puprple_btn:hover::before {
  width: 100%;
}

.puprple_btn:hover {
  color: #212529;
}

.puprple_btn:hover {
  height: 100%;
  /* background-color: var(--bg-white); */
  border-radius: 50px;
  transition: 0.6s all;
  border: 1.5px solid #212529;
  color: #212529;
}

/* Contact Page Css Start */

.contact_page_section .contact_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 40px;
}

.contact_page_section .contact_inner .section_title h2 {
  font-weight: 600;
}

.contact_page_section .contact_inner .contact_form {
  width: 570px;
  padding: 50px 60px;
  border-radius: 22px;
  background-color: #fff;
  box-shadow: 0px 4px 30px #beead58a;
}
.contact_page_section .contact_inner .contact_form h2 {
  margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_form form {
  margin-top: 30px;
}

.contact_page_section .contact_inner .contact_form form .form-group {
  margin-bottom: 20px;
}

.contact_page_section
  .contact_inner
  .contact_form
  form
  .form-group
  .form-control {
  height: 60px;
  padding: 5px 20px;
  color: var(--body-text-purple);
  border: 2px solid #1232581c;
  border-radius: 12px;
  font-weight: 500;
}

.contact_page_section
  .contact_inner
  .contact_form
  form
  .form-group
  .form-control:focus {
  box-shadow: none;
  border-color: #123258;
  color: #123258;
}
.contact_page_section
  .contact_inner
  .contact_form
  form
  .form-group
  textarea.form-control {
  height: 140px;
  padding-top: 15px;
  resize: none !important;
}

.contact_page_section .contact_inner .contact_form form .term_check {
  display: flex;
  align-items: center;
}

.contact_page_section .contact_inner .contact_form form .term_check input {
  width: 17px;
  height: 17px;
  accent-color: var(--purple);
}

.contact_page_section .contact_inner .contact_form form .term_check label {
  font-size: 13px;
  margin-bottom: 0;
  margin-left: 7px;
}

.contact_page_section .contact_inner .contact_form form .form-group button {
  width: 240px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info {
  width: 480px;
}

.contact_page_section .contact_inner .contact_info .section_title {
  text-align: left;
  margin-top: 15px;
}

.contact_page_section .contact_inner .contact_info .section_title p a {
  color: var(--purple);
  text-decoration: underline;
}

.contact_page_section .contact_inner .contact_info .btn {
  width: 180px;
  margin: 10px 0 50px 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact_page_section
  .contact_inner
  .contact_info
  .contact_info_list
  li:last-child {
  margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .img {
  width: 65px;
  margin-right: 20px;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text {
  width: calc(100% - 85px);
  text-align: left;
}

.contact_page_section
  .contact_inner
  .contact_info
  .contact_info_list
  li
  .text
  span {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.contact_page_section
  .contact_inner
  .contact_info
  .contact_info_list
  li
  .text
  p,
.contact_page_section
  .contact_inner
  .contact_info
  .contact_info_list
  li
  .text
  a {
  margin: 0;
}

.contact_page_section
  .contact_inner
  .contact_info
  .contact_info_list
  li
  .text
  a:hover {
  color: #000000;
}

.contact_page_section {
  margin-bottom: 100px !important;
}

/* ------Footer-Css-Start-------------- */
/* footer wraper */
footer {
  position: relative;
}

footer .top_footer {
  background-color: #f6f8fb;
  padding: 100px 0 60px 0;
  position: relative;
  overflow: hidden;
  text-align: left;
}

footer .top_footer .container {
  position: relative;
}

/* footer logo */
footer .top_footer .logo {
  margin-bottom: 40px;
}

footer .top_footer .logo img {
  width: 250px;
}

footer .top_footer .abt_side li {
  padding: 0 0 10px 0;
}

/* footer social media icon */
footer .top_footer .social_media {
  display: flex;
  margin-top: 20px;
}

/* footer link list */
footer .top_footer .social_media li a {
  display: block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.4s all;
}

footer .top_footer .social_media li a:hover {
  background-color: var(--bg-white);
  color: var(--purple);
}

footer .top_footer .try_out {
  margin-left: -20px;
}

/* footer .app_btn li a {
    display: block;
    padding: 12px 10px;
    background-color: var(--bg-white);
    border: 2px solid var(--purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: 175px;
    text-align: center;
} */

footer .app_btn li a:hover {
  -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
}

footer .app_btn li:last-child {
  margin-top: 20px;
}

footer .bottom_footer {
  background-color: #123258;
}

/* footer heading and text colors variable */
footer h2,
footer h3,
footer p,
footer a {
  color: #123258;
}

footer a:hover {
  color: #123258;
}

/* footer heading h3 */
footer h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  /* padding-top: 10px; */
}

footer .links ul li a {
  display: block;
  margin-bottom: 10px;
}

/* footer last */
footer .bottom_footer {
  padding: 15px 0;
}

footer .bottom_footer p {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff !important
  ;
  text-align: left;
}

footer .bottom_footer .developer_text {
  text-align: right;
}

footer .bottom_footer .developer_text a {
  text-decoration: underline;
}

/* footer shape animation */
.top_footer .banner_shape1,
.banner_shape2,
.banner_shape3 {
  position: absolute;
}

.top_footer .banner_shape1 {
  top: 200px;
  left: -15%;
  animation: mymove 10s infinite;
}

.top_footer .banner_shape2 {
  top: 100px;
  right: -10%;
  animation: mymove 5s infinite;
}

.top_footer .banner_shape3 {
  top: 400px;
  right: -20%;
  animation: mymove 3s infinite;
}

/* footer go top button */
.go_top {
  position: fixed;
  right: 30px;
  bottom: 75px;
  cursor: pointer;
  transition: 0.4s all;
  opacity: 1;
  z-index: 999;
}

.go_top:hover {
  bottom: 80px;
}

.cursor-pointer {
  cursor: pointer;
}

/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
  animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
  position: relative;
  animation: moving_position_animatin 6s infinite linear;
  z-index: -1;
  width: 100%;
  margin-top: 40px;
}

.about_app_section .about_img .screen_img img {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  animation-delay: 3s;
}

@keyframes moving_object {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moving_position_animatin {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

/*------Header Btn Animation------*/
@keyframes pulse-blue-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  100%,
  30% {
    box-shadow: 0 0 0 12px transparent;
  }
}

@keyframes pulse-blue-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  100%,
  30% {
    box-shadow: 0 0 0 20px transparent;
  }
}

footer h2,
footer h3,
footer p,
footer a {
  color: #123258 !important;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 !important;
}

.section_title {
  text-align: center;
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999999;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #18df80;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #123258;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #123258;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

/* blog section css here  */

.blog-list-slide img {
  height: 350px;
  /* width: 100%; */
  object-fit: cover;
  width: 550px !important;
}

.blog-list-slide-text p {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.blog-list-slide-text h2 {
  color: #0a253b;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog-list-slide-text a {
  background-color: #123258;
  padding: 10px 20px;
  text-decoration: none;
  color: #fff;
  display: block;
  width: 120px;
  font-size: 14px;
  margin-top: 15px;
  border-radius: 5px;
}

.blog-list-text-slide {
  padding-left: 30px;
}

.blog-list-card img {
  width: 150px;
  object-fit: cover;
  height: 125px;
  border-radius: 5px;
}

.blog-list-card-text p {
  margin-bottom: 8px;
}

.blog-list-card-text {
  padding-left: 20px;
}

.blog-list-card-text h2 {
  font-size: 18px;
  color: #123258;
}

.blog-list-card {
  background: #fff;
  padding: 15px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(10, 37, 59, 0.1);
  display: flex;
  text-decoration: none;
  color: #273353;
}

.blog-list-card a {
  /* background-color: #0a253b; */
  padding: 8px 15px;
  text-decoration: none;
  color: #0a253b;
  border: 1px solid #0a253b;
  font-size: 12px;
  display: block;
  width: 100px;
  margin-top: 10px;
  border-radius: 5px;
  transition: 0.4s ease;
}

.blog-list-slide {
  display: flex;
  /* justify-content: center; */
}

.blog-list-card a:hover {
  background-color: #123258;
  color: #fff;
}

.blog-list-carousel .owl-dot span {
  background: #d9d9d9;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  display: block;
  border-radius: 50%;
}

.blog-list-carousel .owl-dot.active span {
  background: #273353;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  display: block;
  border-radius: 50%;
}

.blog-list-carousel .owl-dots {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
}

.blog-heading-main {
  text-align: center;
  margin-bottom: 40px;
}

.blog-heading-main h2 {
  font-size: 35px;
  font-weight: 700;
  color: #123258;
}

.blog-list-text h4 {
  color: #123258;
  margin-top: 30px;
}

.blog-list-text p {
  font-size: 15px;
}

.blog-date {
  font-size: 20px;
  font-weight: 600;
  color: #123258;
  margin-top: 30px;
}

section.page-mt {
  padding-top: 120px;
  margin-bottom: 60px;
}

.toast-set {
  margin-top: 60px;
}

.blog-date-post .day {
  background: #f7f7f7;
  border-radius: 2px 2px 0 0;
  color: #273353 !important;
  display: block;
  font-size: 19px;
  font-weight: 700;
  padding: 5px 10px;
}
.blog-date-post .month {
  background-color: #273353 !important;
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 10px;
  text-transform: uppercase;
}
.recent-heading {
  width: 80px;
  height: 50px;
  border-bottom: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 3px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -1px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  background: #fff;
  position: relative;
}
.blog-post-li {
  border: 1px solid #eee;
  padding: 0 15px !important;
}
.blog-post-li li {
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  padding-top: 15px;
}
.side-blog-img img {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.post-info a {
  color: #273353 !important;
  font-weight: 500 !important;
  font-size: 14px;
  text-decoration: none;
  line-height: 1.5;
  display: inline-block;
}
.post-meta {
  color: #0b253c;
  font-size: 11px;
}
.side-blog-img {
  margin-right: 10px;
}
.blog-heading-layout {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}
.blog-title-post {
  font-size: 34px;
  font-weight: 600;
  color: #273353;
  line-height: 1.3;
}
.blog-date-post {
  margin-right: 10px;
}
.leave-comment-part {
  margin-top: 40px;
}
.black-lg-text-heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.comment-form {
  background: #f7f7f7;
  padding: 40px;
  margin-top: 20px;
  border-radius: 10px;
}
.comment-form label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.comment-form input {
  box-shadow: none;
  outline: 0;
}
.submit-btn-form {
  background-color: #273353;
  border: 0;
  color: #fff;
  height: 45px;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: 500;
  border-radius: 5px;
}
.blog-comment-form-part {
  margin-top: 50px;
}

.blog-lg-img img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.social-form-new {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.social-form-new::-webkit-scrollbar {
  display: none;
}

.testimonial_img img {
  width: 130px !important;
}

.sub_points {
  list-style: decimal;
  padding: 0 0 0 25px;
}
.privacy_box {
  max-width: 750px;
  padding: 0px 40px;
}

.privacy_box li {
  list-style: none;
  display: flex;
  margin: 0 0 7px 0;
  font-weight: 400;
  font-size: 14px;
}

.privacy_box li p {
  margin: 0;
  width: 13%;
}

.privacy_box li span {
  width: 80%;
}

.border_box {
  border: 1px solid #efefef;
}

.header-logo .main-logo img {
  height: 70px;
  margin-bottom: 30px;
}
